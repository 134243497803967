let hHeader = 0;
$(function() {
    hHeader = $('.header').height();
    $('.header').height(hHeader);
    let menuOpen = false;
    $('.cd-nav-trigger').on('click', function(e) {
        e.preventDefault();
        menuOpen = !menuOpen;
        $(this).toggleClass('cd-nav-trigger--close', menuOpen);
        $('#mainNav, body').toggleClass('nav--open', menuOpen);
        if(!$('.header').hasClass('header--onintro')) $('body').css('padding-top', (menuOpen?hHeader:0)+'px');
    });
});
$( window ).on( "resize", function() {
    $('.header').css('height', '');
    hHeader = $('.header').height();
    $('.header').height(hHeader);
} );