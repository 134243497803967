// Import Swiper JS
import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
const swiper = new Swiper('.swiper', {
    modules: [ Autoplay, Pagination ],
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    centeredSlides: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
    },
    pagination: {
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
    },
});