import '../css/intro.scss'
import './partials/global'
import './partials/video'
import './partials/reviews'
import jQuery from 'jquery'
let tpj, revapi12;
window.$ = window.jQuery = tpj = jQuery;
import './rbtools.min'
import './rs6.min'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/modal'

function setREVStartSize(e){
    window.RSIW = window.RSIW===undefined ? window.innerWidth : window.RSIW;
    window.RSIH = window.RSIH===undefined ? window.innerHeight : window.RSIH;
    try {
        var pw = document.getElementById(e.c).parentNode.offsetWidth,
            newh;
        pw = pw===0 || isNaN(pw) || (e.l=="fullwidth" || e.layout=="fullwidth") ? window.RSIW : pw;
        e.tabw = e.tabw===undefined ? 0 : parseInt(e.tabw);
        e.thumbw = e.thumbw===undefined ? 0 : parseInt(e.thumbw);
        e.tabh = e.tabh===undefined ? 0 : parseInt(e.tabh);
        e.thumbh = e.thumbh===undefined ? 0 : parseInt(e.thumbh);
        e.tabhide = e.tabhide===undefined ? 0 : parseInt(e.tabhide);
        e.thumbhide = e.thumbhide===undefined ? 0 : parseInt(e.thumbhide);
        e.mh = e.mh===undefined || e.mh=="" || e.mh==="auto" ? 0 : parseInt(e.mh,0);
        if(e.layout==="fullscreen" || e.l==="fullscreen")
            newh = Math.max(e.mh,window.RSIH);
        else{
            e.gw = Array.isArray(e.gw) ? e.gw : [e.gw];
            for (var i in e.rl) if (e.gw[i]===undefined || e.gw[i]===0) e.gw[i] = e.gw[i-1];
            e.gh = e.el===undefined || e.el==="" || (Array.isArray(e.el) && e.el.length==0)? e.gh : e.el;
            e.gh = Array.isArray(e.gh) ? e.gh : [e.gh];
            for (var i in e.rl) if (e.gh[i]===undefined || e.gh[i]===0) e.gh[i] = e.gh[i-1];

            var nl = new Array(e.rl.length),
                ix = 0,
                sl;
            e.tabw = e.tabhide>=pw ? 0 : e.tabw;
            e.thumbw = e.thumbhide>=pw ? 0 : e.thumbw;
            e.tabh = e.tabhide>=pw ? 0 : e.tabh;
            e.thumbh = e.thumbhide>=pw ? 0 : e.thumbh;
            for (var i in e.rl) nl[i] = e.rl[i]<window.RSIW ? 0 : e.rl[i];
            sl = nl[0];
            for (var i in nl) if (sl>nl[i] && nl[i]>0) { sl = nl[i]; ix=i;}
            var m = pw>(e.gw[ix]+e.tabw+e.thumbw) ? 1 : (pw-(e.tabw+e.thumbw)) / (e.gw[ix]);
            newh =  (e.gh[ix] * m) + (e.tabh + e.thumbh);
        }
        var el = document.getElementById(e.c);
        if (el!==null && el) el.style.height = newh+"px";
        el = document.getElementById(e.c+"_wrapper");
        if (el!==null && el) {
            el.style.height = newh+"px";
            el.style.display = "block";
        }
    } catch(e){
        console.log("Failure at Presize of Slider:" + e)
    }
}
setREVStartSize({c: 'welcome',rl:[1240,1024,778,480],el:[900,800,500,600],gw:[1240,1024,778,480],gh:[900,800,500,600],type:'hero',justify:'',layout:'fullscreen',offsetContainer:'',offset:'',mh:"0"});
if(typeof revslider_showDoubleJqueryError === "undefined") {
    function revslider_showDoubleJqueryError(sliderID) {
        console.log("You have some jquery.js library include that comes after the Slider Revolution files js inclusion.");
        console.log("To fix this, you can:");
        console.log("1. Set 'Module General Options' -> 'Advanced' -> 'jQuery & OutPut Filters' -> 'Put JS to Body' to on");
        console.log("2. Find the double jQuery.js inclusion and remove it");
        return "Double Included jQuery Library";
    }
}
if (window.RS_MODULES!==undefined && window.RS_MODULES.modules!==undefined && window.RS_MODULES.modules["welcome"]!==undefined) {
    window.RS_MODULES.modules["welcome"].once = false;
    window.revapi12 = undefined;
    if (window.RS_MODULES.checkMinimal!==undefined) window.RS_MODULES.checkMinimal()
}
if(window.RS_MODULES === undefined) window.RS_MODULES = {};
if(RS_MODULES.modules === undefined) RS_MODULES.modules = {};
window.RS_MODULES = window.RS_MODULES || {};
window.RS_MODULES.modules = window.RS_MODULES.modules || {};
window.RS_MODULES.waiting = window.RS_MODULES.waiting || [];
window.RS_MODULES.defered = true;
window.RS_MODULES.moduleWaiting = window.RS_MODULES.moduleWaiting || {};
window.RS_MODULES.type = 'compiled';
RS_MODULES.modules["welcome"] = {
    once: RS_MODULES.modules["welcome"]!==undefined ? RS_MODULES.modules["welcome"].once : undefined,
    init: function() {
        window.revapi12 = window.revapi12===undefined || window.revapi12===null || window.revapi12.length===0  ? document.getElementById("welcome") : window.revapi12;
        if(window.revapi12 === null || window.revapi12 === undefined || window.revapi12.length==0) {
            window.revapi12initTry = window.revapi12initTry ===undefined ? 0 : window.revapi12initTry+1;
            if (window.revapi12initTry<20) requestAnimationFrame(function() {
                RS_MODULES.modules["welcome"].init()
                console.log('RS_MODULES rev init on null');
            });
            return;
        }
        revapi12 = window.revapi12 = jQuery(window.revapi12);
        if(window.revapi12.revolution==undefined){
            revslider_showDoubleJqueryError("welcome");
            return;
        }
        revapi12.revolutionInit({
            revapi:"revapi12",
            sliderType:"hero",
            DPR:"dpr",
            sliderLayout:"fullscreen",
            visibilityLevels:"1240,1024,778,480",
            gridwidth:"1240,1024,778,480",
            gridheight:"900,800,500,600",
            lazyType:"smart",
            spinner:"spinner7",
            perspective:600,
            perspectiveType:"local",
            keepBPHeight:true,
            editorheight:"900,800,500,600",
            responsiveLevels:"1240,1024,778,480",
            progressBar:{disableProgressBar:true},
            navigation: {
                onHoverStop:false
            },
            parallax: {
                levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,30],
                type:"scroll",
                origo:"slidercenter",
                speed:0
            },
            viewPort: {
                global:true,
                globalDist:"-200px",
                enable:false
            },
            fallbacks: {
                allowHTML5AutoPlayOnAndroid:true
            },
        });
    }
}; // End of RevInitScript
if (window.RS_MODULES.checkMinimal!==undefined) { window.RS_MODULES.checkMinimal();}

// Contact form
const contactForm = document.querySelector('.contact-form')
if (contactForm !== null) {
    contactForm.addEventListener('submit', function(e) {
        e.preventDefault()
        const form = new FormData(contactForm)
        const formData = Object.fromEntries(form)

        const loader = contactForm.querySelector('.form-loader')
        loader.classList.add('visible')

        const formFields = contactForm.querySelectorAll('.form-field')
        formFields.forEach((item) => item.classList.remove('form-invalid'))

        fetch(`${window.script_params.fetch_url}?action=contact_form`, {
            method: 'POST',
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then((data) => {
            loader.classList.remove('visible')
            let formResponse = contactForm.querySelector('.form-response')
            if (data?.success) {
                formResponse.innerHTML = data.message
                contactForm.reset()
                // GTM
                if (window.dataLayer) {
                    dataLayer.push({
                        event: 'form_submit',
                        data: formData
                    })
                }
            } else if (data?.errors) {
                Object.entries(data.errors).forEach(([key, value]) => {
                    const input = contactForm.querySelector(`.form-field[name="${key}"`)
                    input ? input.classList.add('form-invalid') : null
                })
                formResponse.innerHTML = data.message
            }
            formResponse.classList.add('visible')
        })
        .catch((error) => {})
    })
}